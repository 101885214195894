<template>
  <base-modal-form
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <div class="flex space-x-4">
        <b-form-group
          label="Nombres"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.name)"
        >
          <b-form-input
            name="name"
            v-model.trim="$v.formFields.name.$model"
            :state="validateState('name')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Dirección"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.address)"
        >
          <b-form-input
            name="address"
            v-model.trim="$v.formFields.address.$model"
            :state="validateState('address')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Ciudad"
          class="flex-1 required"
          :state="validateState('city_id')"
        >
          <input
            type="hidden"
            name="city_id"
            v-model="$v.formFields.city_id.$model"
          />
          <v-select
            :options="cities"
            :reduce="city => city.value"
            label="text"
            v-model.trim="$v.formFields.city_id.$model"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="email"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.email)"
        >
          <b-form-input
            name="email"
            v-model.trim="$v.formFields.email.$model"
            :state="validateState('email')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Teléfono"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.phone)"
        >
          <b-form-input
            name="phone"
            v-model.trim="$v.formFields.phone.$model"
            :state="validateState('phone')"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required, minLength, email } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";

export default {
  props: ["handlePostSubmit", "record"],
  mixins: [customComponentActions],
  inject: ["courtsRepository", "citiesRepository"],

  components: {
    BaseModalForm
  },

  data() {
    return {
      formFields: {
        id: null,
        name: "",
        address: "",
        city_id: null,
        email: "",
        phone: ""
      },

      repository: "courtsRepository",
      primary: "id",
      isString: ["phone"],

      cities: []
    };
  },

  validations: {
    formFields: {
      name: {
        required,
        minLength: minLength(3)
      },
      address: {
        required,
        minLength: minLength(3)
      },
      city_id: {
        required
      },
      email: {
        required,
        email,
        minLength: minLength(3)
      },
      phone: {
        required,
        minLength: minLength(3)
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    }
  },

  async mounted() {
    const me = this;

    let cities = await me.citiesRepository.get();

    me.cities = [
      {
        value: null,
        text: "Seleccione ciudad"
      },
      ...deserialize(cities.data).map(city => {
        return {
          value: city.id,
          text: city.name
        };
      })
    ];
  }
};
</script>

<style lang="scss" scoped></style>
