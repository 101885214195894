<template>
  <div>
    <base-module :moduleOptions="options" title="Juzgados" ref="module">
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <h3>{{ slotProps.name }}</h3>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">email:</span> {{ slotProps.email }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Teléfono:</span> {{ slotProps.phone }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Dirección:</span>
              {{ slotProps.address }}
            </div>
            <div v-if="slotProps.city !== undefined" class="flex-1">
              <span class="font-semibold">Ciudad:</span>
              {{ slotProps.city.name }}
            </div>
          </div>
        </div>
      </template>
    </base-module>
    <court-form :handlePostSubmit="handlePostSubmit" :record="selectedRow" />
  </div>
</template>

<script>
import CourtForm from "./CourtForm";
import BaseModule from "@/components/Base/BaseModule";
import { customComponentActions } from "@/mixins/actions.js";

export default {
  inject: ["courtsRepository", "citiesRepository"],

  mixins: [customComponentActions],

  components: {
    BaseModule,
    CourtForm
  },

  data() {
    return {
      options: {
        columns: [
          {
            header: "Nombre Completo",
            prop: "name"
          },
          {
            header: "Dirección",
            prop: "address"
          },
          {
            header: "Teléfono",
            prop: "phone"
          },
          {
            header: "e-mail",
            prop: "email"
          },
          {
            header: "Ciudad",
            prop: "city.name"
          }
        ],
        repository: {
          name: "courtsRepository"
        },
        sortable: ["name"],
        primary: "id"
      },

      selectedRow: null
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
